<template>
  <div class="modal">
    <div class="modal-background">

      <!-- Alerts -->

      <Alerts />

      <!-- Modals -->
      
      <RemoveMembersModal 
        v-if="showRemoveMembersModal"
        v-bind:members-to-delete="selectedRowsEmails"
        @close="showRemoveMembersModal = false"
        @members-removed="membersRemoveCompleted(1)"
        @members-remove-failed="membersRemoveCompleted(0)"
        @members-remove-partial="membersRemoveCompleted(2)"
      />

      <ResendInviteModal
        v-if="showResendInviteModal"
        v-bind:members-to-resend-invite="selectedRowsEmails"
        v-bind:members-to-resend-invite-status="selectedRowsStatus"
        @close="showResendInviteModal = false"
        @invites-resend-completed="inviteResendCompleted"
      />

      <BulkInviteModal
        v-if="showBulkInviteModal"
        v-bind:isWide=true
        @close="showBulkInviteModal = false"
        @bulk-invite-completed="bulkInviteCompleted"
        @bulk-result-modal="bulkResultModal"
      />

      <BulkRemoveModal
        v-if="showBulkRemoveModal"
        @close="showBulkRemoveModal = false"
        @bulk-remove-completed="bulkRemoveCompleted"
        @bulk-result-modal="bulkResultModal"
      />

      <BulkResultModal
        v-if="showBulkResultModal"
        v-bind:bulkResultStatus="bulkResultStatus"
        v-bind:bulkResultMessageId="bulkResultMessageId"
        v-bind:bulkResultOperationType="bulkResultOperationType"
        @close="showBulkResultModal = false"
        @bulk-invite-modal="openModal('showBulkInviteModal','')"
        @bulk-remove-modal="openModal('showBulkRemoveModal','')"
      />

      <AddRoleModal
        v-if="showAddRoleModal"
        v-bind:addRoleRoleId="addRoleRoleId"
        @close="showAddRoleModal = false"
        @add-role-completed="addRoleCompleted"
        @add-role-result-completed="addRoleResultCompleted"        
      />

      <AddRoleResultModal
        v-if="showAddRoleResultModal"
        v-bind:addRoleResultStatus="addRoleResultStatus"
        v-bind:addRoleResultRoleName="addRoleResultRoleName"
        v-bind:addRoleResultAppName="addRoleResultAppName"
        v-bind:addRoleResultRoleId="addRoleResultRoleId"
        @close="showAddRoleResultModal = false"      
      />   
      
      <DeleteRoleModal
        v-if="showDeleteRoleModal"
        v-bind:deleteRoleRoleName="deleteRoleRoleName"
        v-bind:deleteRoleAppName="deleteRoleAppName"
        @close="showDeleteRoleModal = false"
        @delete-role-completed="deleteRoleCompleted"
        @delete-role-result-completed="deleteRoleResultCompleted"        
      />

      <DeleteRoleResultModal
        v-if="showDeleteRoleResultModal"
        v-bind:deleteRoleResultStatus="deleteRoleResultStatus"
        v-bind:deleteRoleResultRoleName="deleteRoleResultRoleName"
        v-bind:deleteRoleResultAppName="deleteRoleResultAppName"
        @close="showDeleteRoleResultModal = false"      
      />       
      
      <ManagePermissionsModal
        v-if="showManagePermissionsModal"
        v-bind:apis="linkedApis"
        v-bind:isWide=true
        @add-permission-completed="addPermissionCompleted"
        @remove-permission-completed="removePermissionCompleted"
        @close="showManagePermissionsModal = false"    
      />

      <CancelUserRolesModal
        v-if="showCancelUserRolesModal"
        v-bind:apis="linkedApis"
        v-bind:isWide=true
        @close="showCancelUserRolesModal = false"    
      />

      <CancelUserPermissionsModal
        v-if="showCancelUserPermissionsModal"
        @close="showCancelUserPermissionsModal = false"    
      />      

      <!-- Modals End -->

      <div class="sidebar-wrapper">

        <b-sidebar class="sidebar" id="sidebar-right" right shadow>

          <div class="sidebar-inner">
            <div class="row addTitle">
              Add member to <strong>{{ $store.state.groupName }}</strong>
            </div>
            
              <div class="row add-wrapper" v-if="!addToGroupLoading">
                <div class="addInvitedUser-wrapper">
                  <b-form-input for="email" type="email" placeholder="Enter email" class="addInvitedUser" style="margin-top: 15px;" v-model="newUserEmail"></b-form-input>
                </div>
                <div class="addInvitedUserBtn-wrapper">
                  <button v-if="invitedUsers.length < 5" type="submit" v-on:click="setInvitedUsers(newUserEmail.toLowerCase())" class="addEmailBtn">Add User</button>
                </div>
                <div v-if="invitedUsers.length > 4" style="width: 100%; height: auto; margin-top: 20px;">
                  You can add up to five users. If you need to add more, you should use the Upload members from file feature in the portal.
                </div>
              </div>
              <div style="width: 100%; height: auto; margin-top: 20px;" v-if="addToGroupLoading">
                <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1.5" style="margin-right: 10px;"></b-icon>
                <span class="loadingMsg">Adding and inviting users</span>
              </div>
              <div v-for="invitedUser in invitedUsers" v-bind:key="invitedUser">
                <table class="newUserEmail">
                  <caption style="display: none;"></caption>
                  <th></th>
                  <tr>
                    <td class="newUserEmail-inner">{{ invitedUser }}</td>
                    <td v-if="!addToGroupLoading">
                      <button v-bind:id="invitedUser" class="removeUserEmailBtn" v-on:click="removeUserEmail(invitedUser)">-</button>
                      <b-tooltip v-bind:target="invitedUser" triggers="hover" variant="primary" placement="center">
                        Remove {{ invitedUser }}
                      </b-tooltip>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="buttonsWrapper row d-flex" v-if="!addToGroupLoading">
                <div class="addMembers-wrapper"><button class="addMembers" v-if="invitedUsers.length>0" v-on:click="addNewMember(invitedUsers)">Add {{ invitedUsers.length }} Member<span v-if="invitedUsers.length>1">s</span></button></div>
                <div v-if="invitedUsers.length>0" class="cancelBtn-wrapper"><button class="cancelBtn"  v-on:click="clearEmails()">Clear</button></div>
              </div>

              <!-- Loading message at bottom of modal when groups members are loading -->
              <div class="addNewMembersLoading" v-if="addNewMembersLoading">
                <div class="d-flex load-wrapper">
                  <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
                  <div class="loadingMsg">Loading...</div>
                </div>
              </div>
              
              <div class="edit-msg" v-if="userAddedSuccess">
                <b-icon icon="check-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                <span> <strong>{{ invitedUserEmailMsg }}</strong> was added to <strong>{{ $store.state.groupName }}</strong> successfully!</span>
                <span v-if="inviteSuccess"> <strong>{{ invitedUserEmailMsg }}</strong> has not been invited to <strong>{{ $store.state.groupName }}</strong> yet. A new invite is being generated.</span>
              </div>
              <div v-if="inviteSuccess">
                <b-icon icon="check-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                <span> <strong>{{ invitedUserEmailMsg }}</strong> has been successfully invited to <strong>{{ $store.state.groupName }}</strong>. A new invite is being generated.</span>
              </div>
              <div class="edit-msg" v-if="userAddedError">
                <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                <span> Error when adding <strong>{{ invitedUserEmailMsg }}</strong> to <strong>{{ $store.state.groupName }}</strong>. Please refresh and try again.</span>
              </div>
              <div class="edit-msg" v-if="inviteError">
                <div>
                  <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
                  <span> Error when inviting <strong>{{ invitedUserEmailMsg }}</strong>. Please refresh and try again.</span>
                </div>
              </div>
            
          </div>

        </b-sidebar>

      </div>

      <b-container fluid class="modal-inner">
        <div v-on:click="closeGroup()" class="closeBtn">&#10006;</div>
        <b-row class="table-header-wrapper">
          <img class="table-header-logo-wrapper" style="font-size: 12px;" :src="$store.state.groupLogoURL" :alt="$store.state.groupName + ' logo'" v-if="$store.state.groupLogoURL" />
          <img class="table-header-logo-wrapper" style="font-size: 12px;" src="../assets/images/Wingfoot_0D2F42.svg" alt="Goodyear Wingfoot logo" v-if="!$store.state.groupLogoURL" />
          <span class="app-header-title">
            <strong>{{ $store.state.groupName }}</strong>
          </span>
        </b-row>

        <div>
          <b-card no-body>
            <b-tabs v-model="tabIndex" card>
              <b-tab :title-link-class="linkClass(0)" title="Members" active>
                <b-card-text>
                  <div v-if="$store.state.modalTab == 'members'" class="manage-members-wrapper">
                    <div class="row actions-wrapper">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 search-wrapper">
                        <div class="input-group search-input-group">
                          <span class="input-group-btn">
                            <b-button v-on:click="searchGroupMembers" class="primaryButton search-btn">Search</b-button>
                          </span>
                          <b-form-input v-model="searchPatternInputValue" @keyup.enter="searchGroupMembers" class="search-input"></b-form-input>
                          <span class="input-group-btn">
                            <b-button v-on:click="resetSearchGroupMembers" :disabled="this.searchPatternInputValue == ''" v-bind:class="[this.searchPatternInputValue == '' ? 'resetSearchButton search-btn' : 'primaryButton search-btn']">Reset</b-button>
                          </span>
                        </div>
                      </div>
                      <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 add-members-wrapper">
                        <b-button v-on:click="clearEmails()" v-b-toggle.sidebar-right class="primaryButton" style="text-align: center;">
                          <table>
                            <caption style="display: none;">Add members menu</caption>
                            <th style="display: none;"></th>
                            <tbody>
                              <tr>
                                <td><b-icon icon="plus-circle-fill" style="margin-right: 12px"></b-icon></td>
                                <td style="white-space: normal; text-align: left;">Add Members</td>
                              </tr>
                            </tbody>
                          </table>
                        </b-button>
                      </div>
                      <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 upload-download-members-wrapper">
                        <b-button class="secondaryButton upload-download-members-btn" v-on:click="toggleUploadDownloadMenu()">
                          <tbody>
                            <tr>
                              <td class="upload-download-members-btn-text" style="white-space: normal; text-align: left;">Upload / Download</td>
                              <td><b-icon icon="caret-down" font-scale="0.9" style="margin-left: 8px;"></b-icon></td>
                            </tr>
                          </tbody>
                        </b-button>
                        <table v-if="uploadDownloadMenuClicked" class="uploadDownloadMenu">
                          <caption style="display: none;">Upload/Download members menu</caption>
                          <th style="display: none;"></th>
                          <tbody>
                            <tr class="uploadDownloadMenu-tr">
                              <td v-on:click="openModal('showBulkInviteModal', '')" class="uploadDownloadMenu-data">
                                <b-icon class="uploadDownloadMenu-icon" icon="upload"></b-icon>
                                Upload members
                              </td>
                            </tr>
                            <tr class="uploadDownloadMenu-tr">
                              <td v-on:click="downloadMembers()" class="uploadDownloadMenu-data">
                                <b-icon class="uploadDownloadMenu-icon" icon="download"></b-icon>
                                Download members
                              </td>
                            </tr>
                            <tr class="uploadDownloadMenu-tr">
                              <td v-on:click="openModal('showBulkRemoveModal', '')" class="uploadDownloadMenu-data">
                                <b-icon class="uploadDownloadMenu-icon" icon="trash"></b-icon>
                                Remove members
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-xs-6 col-sm-2 col-md-3 col-lg-2 resend-btn-wrapper">
                        <div v-if="selectedRowsIds.length <= 1" class="resend-invite-wrapper">
                          <h5 v-bind:class="[selectedRowsStatus[0] !== 'Active' && selectedRowsIds.length===1 ? 'action-label' : 'action-label-hidden']">
                            Resend invite to <strong>{{ selectedRowsEmails[0] }}</strong>
                          </h5><br/>
                          <button class="resend-invite-btn" style="background-color: transparent; border: none;" :disabled="selectedRowsStatus[0] === 'Active' || selectedRowsIds.length===0 || selectedRowsIds.length>1" v-on:click="openModal('showResendInviteModal', '')">
                            <b-icon v-bind:class="[selectedRowsStatus[0] !== 'Active' && selectedRowsIds.length===1 ? 'action-icon' : 'action-icon-disabled']" 
                              icon="envelope-fill" font-scale="1.7"></b-icon>
                          </button>
                        </div>
                        <div v-if="selectedRowsIds.length > 1" class="resend-invite-wrapper">
                          <h5 v-bind:class="[selectedRowsIds.length > 1 && validateSelectedRowsStatus() ? 'action-label' : 'action-label-hidden']">
                            Resend invite to <strong>({{ selectedRowsIds.length }})</strong> members
                          </h5><br/>
                          <button class="resend-invite-btn" style="background-color: transparent; border: none;" :disabled="!validateSelectedRowsStatus()" v-on:click="openModal('showResendInviteModal', '')">
                            <b-icon v-bind:class="[validateSelectedRowsStatus() && selectedRowsIds.length > 1 ? 'action-icon' : 'action-icon-disabled']" 
                              icon="envelope-fill" font-scale="1.7"></b-icon>
                          </button>
                        </div>
                      </div>
                      <div class="col-xs-6 col-sm-2 col-md-3 col-lg-2 delete-btn-wrapper">
                        <div class="delete-wrapper">
                          <h5 v-bind:class="[selectedRowsIds.length>0 ? 'action-label' : 'action-label-hidden']">
                            Remove <strong style="font-weight: 700;">({{ selectedRowsIds.length }})</strong> Member<span v-if="selectedRowsIds.length>1">s</span>
                          </h5><br/>
                          <button class="delete-btn" style="background-color: transparent; border: none;" :disabled="selectedRowsIds.length==0" v-on:click="openModal('showRemoveMembersModal', '')">
                            <b-icon v-bind:class="[selectedRowsIds.length>0 ? 'action-icon' : 'action-icon-disabled']" 
                              icon="trash-fill" font-scale="1.7"></b-icon>
                          </button>
                        </div>
                      </div>
                  </div>
                  <b-row class="table-wrapper">
                    <table class="table">
                      <caption style="display: none;"></caption>
                      <th></th>
                      <tr class="tableHeader">
                        <td class="tableHeader-column1" @click="changeMembersSorting('name')">
                          <div class="tableHeader-title">
                            Name
                            <b-icon icon="arrow-up" v-if="membersSortingColumn === 'name'" class="sort-order-transition" v-bind:style="{transform: `rotate(${membersSortingOrderRotateDeg}deg)`}" font-scale="1.1"></b-icon>
                          </div>
                        </td>
                        <td class="tableHeader-column2">
                          <div class="tableHeader-title" @click="changeMembersSorting('email')">
                            Email
                            <b-icon icon="arrow-up" v-if="membersSortingColumn === 'email'" class="sort-order-transition" v-bind:style="{transform: `rotate(${membersSortingOrderRotateDeg}deg)`}" font-scale="1.1"></b-icon>
                          </div>
                        </td>
                        <td class="tableHeader-column3">
                          <div class="tableHeader-title">
                            <b-dropdown toggle-class="members-status-dropdown">
                              <template #button-content>
                                Registration Status &#9662;
                                <template v-if="registrationStatusFilter.length === 1 && registrationStatusFilter[0] === 'Active'">(Active)</template>
                                <template v-if="registrationStatusFilter.length === 1 && registrationStatusFilter[0] === 'Pending'">(Pending)</template>
                              </template>
                              <b-dropdown-form>
                                <b-form-checkbox-group v-model="registrationStatusFilter">
                                  <b-form-checkbox value="Active">Active</b-form-checkbox>
                                  <b-form-checkbox value="Pending">Pending</b-form-checkbox>
                                </b-form-checkbox-group>
                              </b-dropdown-form>
                            </b-dropdown>
                          </div>
                        </td>
                        <td class="tableHeader-column4"></td>
                        <td class="tableHeader-column5">
                          <div id="tooltip-target-1" v-on:click="selectAll(groupMembers)" class="mainSelectionCircle">
                            <b-icon icon="check" class="check-icon" height="0.75em" font-scale="1.3"></b-icon>
                          </div>
                          <b-tooltip target="tooltip-target-1" triggers="hover" variant="primary">
                            {{ tooltipText }}
                          </b-tooltip>
                        </td>
                      </tr>
                      <tr class="spacer" style="border-top: none;">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <div class="tableRow-wrapper">
                        <tr v-bind:class="[(selectedRowsIds.includes(groupMember.id) ? 'userRow-selected' : 'tableRow')]" class="tableRow" v-for="groupMember in currentPageGroupMembers" v-bind:key="groupMember.id">

                          <td v-if="groupMembers.length>0" class="tableData">
                            {{ groupMember.displayName }}
                          </td>
                          <td v-if="groupMembers.length>0" class="tableData">
                            {{ groupMember.email}}
                          </td>
                          <td v-if="groupMembers.length>0">
                            <div v-bind:class="[groupMember.userStatus == 'Active' ? 'tableDataActive' : 'tableDataPending']">{{ groupMember.userStatus }} <span v-if="groupMember.userStatus == 'Pending'" style="padding-left: 5px;"><b-icon icon="exclamation-circle" /></span></div>
                          </td>
                          <td>
                            <b-icon icon="three-dots" font-scale="1.2" style="margin-right: 10px;" v-on:click="showUserMenu(groupMember)"></b-icon>
                            <table v-if="$store.state.userMenuClicked.id === groupMember.id" class="userMenu">
                              <caption style="display: none;">User management options</caption>
                              <th style="display: none;"></th>
                              <tbody>
                                <tr>
                                  <td class="userMenu-data userMenu-title">{{ groupMember.email }}</td>
                                </tr>
                                <tr>
                                  <td class="userMenu-data" v-on:click="toggleModalTabs('roles', groupMember)">
                                    <b-icon class="userMenu-icon pencil" icon="pencil-square"></b-icon>
                                    View / Manage Roles
                                  </td>
                                </tr>
                                <tr>
                                  <td class="userMenu-data" v-on:click="toggleModalTabs('permissions', groupMember)">
                                    <b-icon class="userMenu-icon pencil" icon="pencil-square"></b-icon>
                                    View / Manage User Permissions
                                  </td>
                                </tr>
                                <tr>
                                  <td class="userMenu-data" v-on:click="userMenuActionClicked(groupMember, 'resendInvite')" :disabled="groupMember.userStatus === 'Active'">
                                    <b-icon class="userMenu-icon envelope" icon="envelope"></b-icon>
                                    Resend Invite
                                  </td>
                                </tr>
                                <tr>
                                  <td class="userMenu-data" v-on:click="userMenuActionClicked(groupMember, 'deleteMember')">
                                    <b-icon class="userMenu-icon trash" icon="trash"></b-icon>
                                    Remove
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td v-if="groupMembers.length>0">
                            <div v-bind:class="[selectedRowsIds.includes(groupMember.id) ? 'selectionCircle-selected' : 'selectionCircle']" v-on:click="selectRow(groupMember)" class="selectionCircle">
                              <b-icon v-if="selectedRowsIds.includes(groupMember.id)" icon="check" class="check-icon" height="0.7em" font-scale="1.2"></b-icon>
                            </div>
                          </td>
                        </tr>
                      </div>

                      <tr v-if="groupMembers.length==0 && !groupMembersLoading">
                        <strong>This application does not contain any members.</strong>
                      </tr>
                      <tr v-if="groupMembers.length !==0 && sortedFilteredGroupMembers.length === 0 && !groupMembersLoading">
                        <strong>There are no group members that match your search.</strong>
                      </tr>

                    </table>
                    
                  </b-row>

                    <div class="row members-pagination-wrapper" v-if="!groupMembersLoading && groupMembers.length !== 0 && sortedFilteredGroupMembers.length !== 0">
                      <div class="col-xs-12 col-sm-8 members-pagination">
                        <paginate
                          v-model="membersPage"
                          :page-count="sortedFilteredMembersPageCount"
                          :page-range="3"
                          :margin-pages="2"
                          prev-text='<span class="glyphicon glyphicon glyphicon-chevron-left prev-next-page-icon" aria-hidden="true"></span>'
                          next-text='<span class="glyphicon glyphicon glyphicon-chevron-right prev-next-page-icon" aria-hidden="true"></span>'
                          :container-class="'pagination'"
                          :page-link-class="'page-link'"
                          :prev-link-class="'prev-next-page-link'"
                          :next-link-class="'prev-next-page-link'"
                          :click="closeUserMenu()"
                        >
                        </paginate>
                        
                        </div>

                        <div class="col-xs-12 col-sm-4 members-per-page">
                          <div class="rows-per-page-label">
                            Rows per page:
                          </div>
                          <div>
                            <select v-model="membersPerPage" class="form-control input-sm">
                              <option v-bind:value="10">10</option>
                              <option v-bind:value="25">25</option>
                              <option v-bind:value="50">50</option>
                            </select>
                          </div>
                          <div class="current-page-range">
                            {{currentPageRange}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="$store.state.modalTab == 'roles'" class="roles-tab-content-wrapper">
                    <b-row class="tab-content-header">
                      <b-col class="tab-breadcrumb-wrapper" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <div class="tab-breadcrumb" v-on:click="toggleModalTabs('members', '')">
                          <b-icon icon="arrow-left-circle-fill" font-scale="1.4"></b-icon>
                          <span class="tab-breadcrumb-text">Back to application members</span>
                        </div>
                      </b-col>
                      <b-col class="tab-content-h2-wrapper" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <h2 class="tab-content-h2">Manage <strong>{{ appMemberSelected.email }}'s</strong> Roles</h2>
                      </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="assigned-app-roles-wrapper">
                          <div>
                            <h3 class="tab-content-h3">
                              <strong>Assigned app roles</strong> <em style="padding-left: 10px; font-size: 16px;">click to unassign role</em>
                            </h3>
                            <hr class="tab-content-separator">
                          </div>
                          <div class="assigned-app-roles-wrapper-inner">
                            <div v-if="!rolesLoading">
                              <span v-on:click="addRemoveRolesFromList(role)" v-for="role in $store.state.assignedMemberRoles" v-bind:key="role.index" class="memberRoleTag assigned" :disabled="role === this.$store.state.groupName + ' Role'">
                                {{ role }}
                                <b-icon icon="dash-circle" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                              <span v-on:click="addRemoveRolesFromList(role)" v-for="role in $store.state.newAssignedMemberRoles" v-bind:key="role.index" class="memberRoleTag new">
                                {{ role }}
                                <b-icon icon="dash-circle-dotted" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                            </div>
                            <div class="assignedRolesLoading" v-if="rolesLoading">
                              <div class="d-flex load-wrapper">
                                <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
                                <div class="loadingMsg">Loading...</div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="available-app-roles-wrapper">
                          <div>
                            <h3 class="tab-content-h3">
                              <strong>Available app roles for {{ $store.state.groupName }}</strong> <em style="padding-left: 10px; font-size: 16px;">click to assign role</em>
                            </h3>
                            <hr class="tab-content-separator">
                          </div>
                          <div class="available-app-roles-wrapper-inner">
                            <div v-if="!rolesLoading">
                              <span v-on:click="addRemoveRolesFromList(role)" v-for="role in $store.state.availableMemberRoles" v-bind:key="role.index" class="memberRoleTag available">
                                {{ role }}
                                <b-icon icon="plus-circle" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                              <span v-on:click="addRemoveRolesFromList(role)" v-for="role in $store.state.newAvailableMemberRoles" v-bind:key="role.index" class="memberRoleTag newAvailable">
                                {{ role }}
                                <b-icon icon="dash-circle-dotted" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                            </div>
                            <div class="assignedRolesLoading" v-if="rolesLoading">
                              <div class="d-flex load-wrapper">
                                <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
                                <div class="loadingMsg">Loading...</div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col class="roles-btns-wrapper" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                          <b-button v-on:click="openModal('showCancelUserRolesModal', '')" :disabled="!$store.state.rolesUpdated" class="btn secondaryButton" style="float: left; margin-right: 10px; margin-bottom: 10px;">Cancel</b-button>
                          <b-button v-on:click="updateUserRoles($store.state.newAvailableMemberRoles, $store.state.newAssignedMemberRoles, appMemberSelected.email, $store.state.token)" :disabled="!$store.state.rolesUpdated" class="btn primaryButton" style="margin-bottom: 10px;">Apply Updates</b-button>
                        </b-col>
                      </b-row>
                  </div>

                  <div v-if="$store.state.modalTab == 'permissions'" class="roles-tab-content-wrapper">
                    <b-row class="tab-content-header">
                      <b-col class="tab-breadcrumb-wrapper" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <div class="tab-breadcrumb" v-on:click="toggleModalTabs('members', '')">
                          <b-icon icon="arrow-left-circle-fill" font-scale="1.4"></b-icon>
                          <span class="tab-breadcrumb-text">Back to application members</span>
                        </div>
                      </b-col>
                      <b-col class="tab-content-h2-wrapper" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <h2 class="tab-content-h2">Manage <strong>{{ appMemberSelected.email }}'s</strong> Permissions</h2>
                      </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="assigned-app-roles-wrapper">
                          <div>
                            <h3 class="tab-content-h3">
                              <strong>Assigned app permissions</strong> <em style="padding-left: 10px; font-size: 16px;">click to unassign permission</em>
                            </h3>
                            <hr class="tab-content-separator">
                          </div>
                          <div class="assigned-app-roles-wrapper-inner">
                            <div v-if="!rolesLoading">
                              <span v-on:click="addRemovePermissionsFromList(permission)" v-for="permission in $store.state.assignedMemberPermissions" v-bind:key="permission.index" class="memberRoleTag assigned" :disabled="permission === this.$store.state.groupName + ' Permission'">
                                {{ permission.name }} : <span style="font-weight: normal;">{{ permission.apiName }} API</span>
                                <b-icon icon="dash-circle" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                              <span v-on:click="addRemovePermissionsFromList(permission)" v-for="permission in $store.state.newAssignedMemberPermissions" v-bind:key="permission.index" class="memberRoleTag new">
                                {{ permission.name }} : <span style="font-weight: normal;">{{ permission.apiName }} API</span>
                                <b-icon icon="dash-circle-dotted" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                            </div>
                            <div class="assignedRolesLoading" v-if="rolesLoading">
                              <div class="d-flex load-wrapper">
                                <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
                                <div class="loadingMsg">Loading...</div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="available-app-roles-wrapper">
                          <div>
                            <h3 class="tab-content-h3">
                              <strong>Available app permissions for {{ $store.state.groupName }}</strong> <em style="padding-left: 10px; font-size: 16px;">click to assign permission</em>
                            </h3>
                            <hr class="tab-content-separator">
                          </div>
                          <div class="available-app-roles-wrapper-inner">
                            <div v-if="!rolesLoading">
                              <span v-on:click="addRemovePermissionsFromList(permission)" v-for="permission in $store.state.availableMemberPermissions" v-bind:key="permission.index" class="memberRoleTag available">
                                {{ permission.name }} : <span style="font-weight: normal;">{{ permission.apiName }} API</span>
                                <b-icon icon="plus-circle" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                              <span v-on:click="addRemovePermissionsFromList(permission)" v-for="permission in $store.state.newAvailableMemberPermissions" v-bind:key="permission.index" class="memberRoleTag newAvailable">
                                {{ permission.name }} : <span style="font-weight: normal;">{{ permission.apiName }} API</span>
                                <b-icon icon="dash-circle-dotted" font-scale="1.2" style="margin-left: 6px; margin-top: 5px;"></b-icon>
                              </span>
                            </div>
                            <div class="assignedRolesLoading" v-if="rolesLoading">
                              <div class="d-flex load-wrapper">
                                <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
                                <div class="loadingMsg">Loading...</div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col class="roles-btns-wrapper" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                          <b-button v-on:click="openModal('showCancelUserPermissionsModal', '')" :disabled="!$store.state.permissionsUpdated" class="btn secondaryButton" style="float: left; margin-right: 10px; margin-bottom: 10px;">Cancel</b-button>
                          <b-button v-on:click="updateUserPermissions($store.state.newAvailableMemberPermissions, $store.state.newAssignedMemberPermissions, appMemberSelected.email, $store.state.token)" :disabled="!$store.state.permissionsUpdated" class="btn primaryButton" style="margin-bottom: 10px;">Apply Updates</b-button>
                        </b-col>
                      </b-row>
                  </div>


                </b-card-text>
              </b-tab>
              <b-tab :title-link-class="linkClass(1)" title="Roles">
                <b-card-text>
                  <b-row class="tableTitle-wrapper">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="tableTitle">
                      Manage roles for <strong>{{ $store.state.groupName }}</strong>
                    </b-col>
                    <div v-on:click="closeGroup()" class="closeBtn">&#10006;</div>
                  </b-row>

                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6">
                      <b-button :disabled="groupMembersLoading" v-on:click="openModal('showAddRoleModal', '')" class="primaryButton">
                        <b-icon icon="plus-circle-fill" style="margin-right: 8px;"></b-icon> Add New Role
                      </b-button>
                    </div>
                  </div>

                  <b-row class="table-wrapper">
                    <table class="table">
                      <caption style="display: none;"></caption>
                      <th></th>
                      <tr class="tableHeader">
                        <td class="tableHeader-column1">
                          <div class="tableHeader-title">
                            Role Name
                          </div>
                        </td>
                        <td class="tableHeader-column2">
                          <div class="tableHeader-title">
                            Role Description
                          </div>
                        </td>
                        <td class="tableHeader-column3">
                          <div class="tableHeader-title">                            
                          </div>
                        </td>                        
                        <td class="tableHeader-column6">
                        </td>
                      </tr>
                      <tr class="spacer" style="border-top: none;">
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr class="spacer" style="border-top: none;">
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>                      

                      <div class="tableRow-wrapper">
                        <tr class="tableRow" v-for="appRole in appRoles" v-bind:key="appRole.id">
                          <td v-if="appRoles.length > 0" class="tableData">
                            {{ appRole.name }}
                          </td>
                          <td v-if="appRoles.length > 0" class="tableData">
                            {{ appRole.description}}
                          </td>
                          <td v-if="appRoles.length > 0">
                            <div style="background-color: transparent; border: none; cursor: pointer; text-align: center; vertical-align: middle;" v-on:click="openModal('showAddRoleModal', appRole.id)">
                              <b-icon icon="pencil" font-scale="1.3"></b-icon>
                            </div>
                          </td>
                          <td v-if="appRoles.length > 0">
                            <div style="background-color: transparent; border: none; cursor: pointer; text-align: center; vertical-align: middle;" v-on:click="openModal('showDeleteRoleModal', appRole.name)">
                              <b-icon icon="trash" font-scale="1.3" style="color: red;"></b-icon>
                            </div>
                          </td>                          
                        </tr>
                      </div>

                      <tr v-if="appRoles.length == 0 && !groupMembersLoading">
                        <strong>This application does not contain any roles.</strong>
                      </tr>
                    </table>
                  </b-row>

                </b-card-text>
              </b-tab>
              <b-tab :title-link-class="linkClass(2)" title="Permissions">
                <b-card-text>
                  <b-row class="tableTitle-wrapper">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="tableTitle">
                      Manage permissions for <strong>{{ $store.state.groupName }}</strong>
                    </b-col>
                    <div v-on:click="closeGroup()" class="closeBtn">&#10006;</div>
                  </b-row>

                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6">
                      <b-button :disabled="linkedApis.length === 0" v-on:click="openModal('showManagePermissionsModal', '')" class="primaryButton">
                        Manage Permissions
                      </b-button>
                    </div>
                  </div>

                  <b-row class="table-wrapper">
                    <table class="table">
                      <caption style="display: none;"></caption>
                      <th></th>
                      <tr class="tableHeader">
                        <td class="tableHeader-column1">
                          <div class="tableHeader-title">
                            Permission Name
                          </div>
                        </td>
                        <td class="tableHeader-column2">
                          <div class="tableHeader-title">
                            Permission Description
                          </div>
                        </td>
                        <td class="tableHeader-column3">
                          <div class="tableHeader-title">
                            Permission API
                          </div>
                        </td>
                        <td class="tableHeader-column4">
                          <div class="tableHeader-title">
                            API Identifier
                          </div>
                        </td>       
                        <td class="tableHeader-column6">
                        </td>                                          
                      </tr>
                      <tr class="spacer" style="border-top: none;">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <div class="tableRow-wrapper">
                        <tr class="tableRow" v-for="appPermission in appPermissions" v-bind:key="appPermission.id">
                          <td v-if="appPermissions.length > 0" class="tableData">
                            {{ appPermission.name }}
                          </td>
                          <td v-if="appPermissions.length > 0" class="tableData">
                            {{ appPermission.description}}
                          </td>
                          <td v-if="appPermissions.length > 0">
                            {{ appPermission.api}}
                          </td>
                          <td v-if="appPermissions.length > 0">
                            {{ appPermission.apiIdentifier}}
                          </td>
                          <td></td>
                        </tr>
                      </div>

                      <tr v-if="appPermissions.length == 0 && !groupMembersLoading">
                        <strong>This application does not contain any permissions.</strong>
                      </tr>
                    </table>                    
                  </b-row>

                </b-card-text>
              </b-tab>              
            </b-tabs>
          </b-card>
        </div>

      </b-container>
      <!-- <div v-if="$store.state.userMenuClicked != ''" class="userMenu-clickableBackground" v-on:click="closeUserMenu()"></div> -->

      <div class="groupMembersLoading" v-if="groupMembersLoading">
        <div class="d-flex load-wrapper">
          <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
          <div class="loadingMsg">Loading...</div>
        </div>
      </div>

    </div>
    <div class="modalOverlay" v-on:click="closeGroup()"></div>
    
  </div>
</template>

<script>
import axios from 'axios';
import { apiPath } from '../authConfig';
axios.defaults.baseURL = apiPath;
import Alerts from '../components/Alerts';
import RemoveMembersModal from '../components/modals/RemoveMembersModal';
import ResendInviteModal from '../components/modals/ResendInviteModal';
import BulkInviteModal from '../components/modals/BulkInviteModal';
import BulkRemoveModal from '../components/modals/BulkRemoveModal';
import BulkResultModal from '../components/modals/BulkResultModal';
import AddRoleModal from '../components/modals/AddRoleModal';
import AddRoleResultModal from '../components/modals/AddRoleResultModal';
import DeleteRoleModal from '../components/modals/DeleteRoleModal';
import DeleteRoleResultModal from '../components/modals/DeleteRoleResultModal';
import ManagePermissionsModal from '../components/modals/ManagePermissionsModal';
import CancelUserRolesModal from '../components/modals/CancelUserRolesModal'
import CancelUserPermissionsModal from '../components/modals/CancelUserPermissionsModal';

export default {
  name: "GroupMembers",
  components: {
    Alerts,
    RemoveMembersModal,
    ResendInviteModal,
    BulkInviteModal,
    BulkRemoveModal,
    BulkResultModal,
    AddRoleModal,
    AddRoleResultModal,
    DeleteRoleModal,
    DeleteRoleResultModal,
    ManagePermissionsModal,
    CancelUserRolesModal,
    CancelUserPermissionsModal
  },
  data: function () {
    return {
      groupMembers:[],
      groupMembersLoading: false,
      newUserRoles: [],
      rolesLoading: false,
      showModal:false,
      showInviteModal:false,
      newUserEmail:'',
      invitedUserEmail:'',
      invitedUsers:[],
      invitedUsersLength:0,
      invitedUserEmailMsg:'',
      invitedUserEmailFirstLetters:[],
      userId:'',
      userExists:'',
      userStatus:'',
      userAddedSuccess:false,
      userAddedError:false,
      inviteSuccess:false,
      inviteError:false,
      showRemoveMembersModal:false,
      isInvitedAddedModal:false,
      showResendInviteModal:false,
      showBulkInviteModal:false,
      showBulkRemoveModal:false,
      showBulkResultModal:false,
      appMemberSelected: { email: '', id: ''},
      showAddRoleModal:false,
      showAddRoleResultModal:false,
      showDeleteRoleModal:false,
      showDeleteRoleResultModal:false,      
      selectedRowsIds:[],
      selectedRowsEmails:[],
      selectedRowsStatus:[],
      rowIsSelected:false,
      tooltipText:'Select All',
      iconText:'',
      invitedUsersSuccess:[],
      invitedUsersFail:[],
      addedUsersSuccess:[],
      addedUsersFail:[],
      addNewMembersLoading:false,
      invitedAddedUsersAlert:false,
      selectedResendEmail:null,
      membersSortingOrder: 'ASC', // default
      membersSortingColumn: 'name', // default
      registrationStatusFilter: [],
      searchPatternInputValue:'',
      searchPattern:'',
      membersPage: 1,
      membersPerPage: 10,
      addToGroupLoading: false,
      bulkResultStatus: -1,
      bulkResultMessageId: '',
      bulkResultOperationType: '',
      appRoles:[],
      addRoleResultStatus: -1,
      addRoleResultRoleName: '',   
      addRoleResultAppName: '',  
      addRoleResultRoleId: '', 
      deleteRoleRoleName: '',   
      deleteRoleAppName: '',  
      deleteRoleResultStatus: -1,
      deleteRoleResultRoleName: '',   
      deleteRoleResultAppName: '', 
      tabIndex: 0,
      appPermissions:[],        
      apis: [],
      showManagePermissionsModal: false,
      showCancelUserRolesModal: false,
      showCancelUserPermissionsModal: false,
      linkedApis: [],
      addRoleRoleId: '',
      uploadDownloadMenuClicked: false
    }
  },
  watch: {
    registrationStatusFilter: function () {
      this.membersPage = 1;
    },
    membersPerPage: function () {
      this.membersPage = 1;
    }
  },
  computed: {
    membersSortingOrderRotateDeg: function() {
      return this.membersSortingOrder === 'ASC' ? 0 : 180;
    },
    sortedFilteredMembersPageCount: function() {
      return Math.ceil(this.sortedFilteredGroupMembers.length / this.membersPerPage);
    },
    currentPageRange: function() {
      let first = (this.membersPage - 1) * this.membersPerPage + 1;
      let last = this.membersPage === this.sortedFilteredMembersPageCount ? this.sortedFilteredGroupMembers.length : first + this.membersPerPage - 1;
      return `${first} - ${last} of ${this.sortedFilteredGroupMembers.length}`
    },
    sortedFilteredGroupMembers: function() {
      let members = [... this.groupMembers];

      // Filter by registration status
      if(this.registrationStatusFilter.length === 1) {
        members = members.filter(user => user.userStatus === this.registrationStatusFilter[0]);
      }

      // Search
      if (this.searchPattern && this.searchPattern !== '') {
        members = members.filter(user => {
          return user.userStatus === 'Active' ? 
            user.displayName?.toLowerCase().includes(this.searchPattern.toLowerCase()) ||
            user.email?.toLowerCase().includes(this.searchPattern.toLowerCase()) ||
            user.userStatus.toLowerCase().includes(this.searchPattern.toLowerCase())
            :
            user.email?.toLowerCase().includes(this.searchPattern.toLowerCase()) ||
            user.userStatus.toLowerCase().includes(this.searchPattern.toLowerCase());
          }
        );
      }

      // Sorting
      if (this.membersSortingColumn === 'name') {
        members.sort(this.compareMembersByName);
      } else {
        members.sort(this.compareMembersByEmail);
      }

      if (this.membersSortingOrder === 'DESC') {
        members.reverse();
      }

      return members;
    },
    currentPageGroupMembers: function() {
      // Pagination
      let startIndex = (this.membersPage - 1) * this.membersPerPage;
      let endIndex = startIndex + this.membersPerPage;

      return this.sortedFilteredGroupMembers.slice(startIndex, endIndex)
    }
  },
  mounted: async function() {
    await this.getGroupMembers();
    await this.getAppRoles();
    await this.getAppPermissions();
    for(const linkedApi of this.linkedApis) {
      if(!linkedApi.scopes){
        linkedApi.scopes = [];
      }        
    }
    this.$store.commit('setModalTab', 'members')
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-info']
      }
    },
    toggleModalTabs: function (tabName, groupMember) {
      this.$store.commit('setModalTab', tabName)
      this.$store.commit('setUserMenuClicked', "")
      if(this.$store.state.modalTab === "roles") {
        this.getUserRoles(groupMember.email)
      }
      if(this.$store.state.modalTab === "permissions") {
        this.getUserPermissions(groupMember.email);
      }
      this.appMemberSelected.id = groupMember.id
      this.appMemberSelected.email = groupMember.email
      this.clearRoles()
      this.clearPermissions();
    },
    getGroupMembers: async function() {
      this.groupMembersLoading = true;
      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/members', {
        headers: { Authorization: this.$store.state.token }
      })
      .then(response => {
        this.groupMembers = response.data.result.members;
        
        for(let i=0; i<= this.groupMembers.length; i++) {
          if(this.groupMembers[i].userStatus !== 'Active') {
            // convert to string to be consistent with other group members ids, type is string
            let id = i.toString();
            this.groupMembers[i].id = id;
          }
        }
        this.groupMembersLoading = false;
      })
      .catch(() => {
        this.groupMembersLoading = false;
      })
    },
    searchGroupMembers: function() {
      this.membersPage = 1
      this.searchPattern = this.searchPatternInputValue

      this.closeUserMenu()
    },
    resetSearchGroupMembers: function() {
      this.membersPage = 1
      this.searchPatternInputValue = ''
      this.searchPattern = ''

      this.closeUserMenu()
    },
    resetPageSortingFilteringValues: function() {
      this.registrationStatusFilter = [];
      this.searchPatternInputValue = '';
      this.searchPattern = '';
      this.membersPage = 1;
    },
    validateEmail: function(newUserEmail) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(newUserEmail) || newUserEmail == '';
    },
    compareEmails(emailsToBeAdded, currentMembers) {
      const currentMembersEmails = [];
      for(let i=0; i<currentMembers.length; i++) {
        currentMembersEmails[i] = currentMembers[i].email;
      }
      return currentMembersEmails.includes(emailsToBeAdded);
    },
    setInvitedUsers: function(newUserEmail) {
      const emailIsValid = this.validateEmail(newUserEmail);
      const userExistsInGroup = this.compareEmails(newUserEmail, this.groupMembers);
      let emailValidated = false

      let duplicateInvite = false
      this.invitedUsers.find(function(item) {
        if(item === newUserEmail) {
          duplicateInvite = true
        }
      });

      if(newUserEmail.length === 0) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter an email.`
        });
      } else if(!emailIsValid) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter a valid email.`
        });
      } else if(userExistsInGroup || duplicateInvite) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `This user already exists in this application. Cannot invite or add duplicate emails.`
        });
      } else {
        emailValidated = true
      }

      if(emailValidated) {
        this.invitedUsers.push(newUserEmail.toLowerCase())
        this.$forceUpdate();
      }
      
    },
    removeUserEmail: function(userEmail) {
      let index = 0
      this.invitedUsers.find(function(item, i) {
        if(item === userEmail) {
          index = i
          return i
        }
      });
  
      this.invitedUsers.splice(index, 1);
    },
    addNewMember: async function(invitedUsers) {
      
      this.addToGroupLoading = true;

      let successRecords = 0;
      let failedRecords = 0;
      let failedUsers = [];

      for (let invitedUser of invitedUsers) {

        let data = {
          "email": invitedUser
        }; 

        await axios.post('/adminportal/applications/' + this.$store.state.groupId + '/members', data,
        {
          headers: {
            Authorization: this.$store.state.token,
            'Content-Type': 'application/json'
          }
        }
        )
        .then(response => {
          if(response.status === 200) {
            successRecords++;
          } else {
            failedUsers.push(data) ;
            failedRecords++;
          }
        })
        .catch(error => {
          failedUsers.push(data) ;
          failedRecords++;
        })
      }
          if(failedRecords === 0) {
            // track gtm event
            window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'add', 'appName': this.$store.state.groupName });

            this.$store.dispatch('alerts/openSuccessAlert', {
              content: `Member(s) have been successfully added/invited to ${this.$store.state.groupName}!`
            });

            // partial success - some users failed
          } else if(failedRecords != 0 && successRecords != 0) {
            // track gtm event
            window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'add-partial', 'appName': this.$store.state.groupName });

            this.$store.dispatch('alerts/openErrorAlert', {
              content: `<div>Failed to add <strong>some member(s)</strong> to ${this.$store.state.groupName}...</div>
                <br><strong>Error occurred when adding email(s). Try again: </strong>
                ${failedUsers.map(user => user.email).join('<br/>')}
              `
            });

            // 400 all users failed
          } else if(successRecords === 0) {
            this.$store.dispatch('alerts/openErrorAlert', {
              content: `<div>Failed to add member(s) to ${this.$store.state.groupName}...</div>
                <br><strong>Error occurred when adding email(s). Try again: </strong>
                ${failedUsers.map(user => user.email).join('<br/>')}
              `
            });
            this.addToGroupLoading = false;
          }
          this.clearEmails();
          this.resetPageSortingFilteringValues();
          this.getGroupMembers();
          this.addToGroupLoading = false;

      this.addNewMembersLoading = false;
      this.getGroupMembers();
    },
    userMenuActionClicked: function(groupMember, actionType) {
      this.selectedRowsEmails[0] = groupMember.email
      this.selectedRowsStatus[0] = groupMember.userStatus
      if (actionType === 'resendInvite') {
        this.openModal('showResendInviteModal', '')
      }
      else if (actionType === 'deleteMember') {
        this.openModal('showRemoveMembersModal', '')
      }
    },
    openModal: function(modalType, groupMember) {
      this.closeUserMenu()
      if(modalType === 'showRemoveMembersModal') {
        this.showRemoveMembersModal = true
      } else if(modalType === 'showResendInviteModal') {
        this.showResendInviteModal = true
      } else if (modalType === 'showBulkInviteModal') {
        this.showBulkInviteModal = true
      } else if (modalType === 'showBulkRemoveModal') {
        this.showBulkRemoveModal = true
      } else if (modalType === 'showAddRoleModal') {
        this.addRoleRoleId = groupMember
        this.showAddRoleModal = true
      } else if (modalType === 'showDeleteRoleModal') {
        this.deleteRoleRoleName = groupMember
        this.deleteRoleAppName = this.$store.state.groupName
        this.showDeleteRoleModal = true
      } else if (modalType === 'showManagePermissionsModal') {
        this.showManagePermissionsModal = true
      } else if (modalType === 'showCancelUserRolesModal') {
        this.showCancelUserRolesModal = true
      } else if (modalType === 'showCancelUserPermissionsModal') {
        this.showCancelUserPermissionsModal = true
      }
    },
    closeModal: function() {
      // Close Modal
      this.showModal = false

      // Reset Modal Type
      this.showInviteModal = false
      this.showRemoveMembersModal = false
      this.showResendInviteModal = false
      this.showBulkInviteModal = false
      this.showBulkRemoveModal = false
      this.showBulkResultModal = false
      this.showAddRoleModal = false
      this.showAddRoleResultModal = false
      this.showDeleteRoleModal = false
      this.showDeleteRoleResultModal = false

      // close user menu
      this.closeUserMenu()
    },
    membersRemoveCompleted: async function(wasRemoveSuccessful) {
      // clear selected rows
      this.selectedRowsIds = [];
      this.selectedRowsEmails = [];
      this.selectedRowsStatus = [];
      this.tooltipText = 'Select All';

      // close user menu
      this.closeUserMenu()

      if (wasRemoveSuccessful > 0) {
        this.resetPageSortingFilteringValues();
        // remove success
        let gtmRemoveType = 'remove';

        if (wasRemoveSuccessful == 2) {
          // partial success
          gtmRemoveType = 'remove-partial';
        }
        // track gtm event
        window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': gtmRemoveType, 'appName': this.$store.state.groupName });
      }

      this.getGroupMembers();
      this.showRemoveMembersModal = false;
    },
    inviteResendCompleted: async function() {
      // clear selected rows
      this.selectedRowsIds = [];
      this.selectedRowsEmails = [];
      this.selectedRowsStatus = [];
      this.tooltipText = 'Select All';

      // close user menu
      this.closeUserMenu()

      // track gtm event
      window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'resend invite', 'appName': this.$store.state.groupName });

      this.showResendInviteModal = false;
    }, 
    bulkInviteCompleted: function() {
      // track gtm event
      window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'bulk invite', 'appName': this.$store.state.groupName });

      this.getGroupMembers();
    },
    bulkRemoveCompleted: function() {
      // track gtm event
      window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'bulk remove', 'appName': this.$store.state.groupName });

      this.getGroupMembers();
    },
    bulkResultModal: function(status, messageId, operationType) {
      this.bulkResultStatus = status;
      this.bulkResultMessageId = messageId;
      this.bulkResultOperationType = operationType;
      this.showBulkResultModal = true;
    },
    addRoleCompleted: function() {
      // track gtm event
      window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'add role', 'appName': this.$store.state.groupName });

      this.getAppRoles();
    },
    addRoleResultCompleted: function(status, roleName, roleId) {
      this.addRoleResultStatus = status;
      this.addRoleResultRoleName = roleName;
      this.addRoleResultRoleId = roleId;
      this.addRoleResultAppName = this.$store.state.groupName;
      this.showAddRoleResultModal = true;
    },  
    deleteRoleCompleted: function() {
      // track gtm event
      window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'delete role', 'appName': this.$store.state.groupName });

      this.getAppRoles();
    },
    deleteRoleResultCompleted: function(status, roleName) {
      this.deleteRoleResultStatus = status;
      this.deleteRoleResultRoleName = roleName;
      this.deleteRoleResultAppName = this.$store.state.groupName;
      this.showDeleteRoleResultModal = true;
    },         
    clearEmails: function() {
      this.userEmail = ''
      this.newUserEmail = ''
      this.invitedUsers = []

      this.closeUserMenu()
    },
    showUserMenu: function (groupMember) {
      if(this.$store.state.userMenuClicked !== "") {
        this.$store.commit('setUserMenuClicked', "")
      } else {
        this.$store.commit('setUserMenuClicked', groupMember)
      }
    },
    closeUserMenu: function () {
      this.$store.commit('setUserMenuClicked', "")
    },
    getUserRoles: async function (userEmail) {
      this.rolesLoading = true
      axios.get('/adminportal/user/roles', {
        headers: { Authorization: this.$store.state.token },
        params: {
          "email": userEmail,
          "applicationId": this.$store.state.groupId
        }
      })
      .then(response => {
        let memberRoles = response.data.result.roles;
        // Set assigned member roles array
        this.$store.commit('setAssignedMemberRoles', memberRoles)
        this.rolesLoading = false
        this.setAvailableAppRoles(this.appRoles);
      })
      .catch(error => {
        this.rolesLoading = false
      })
    },
    setAvailableAppRoles:  function() {
      this.rolesLoading = true

      // remove roles already assigned to the member
      // set an array only including app role names for filter
      let appRoleNames = [];
      for(let i = 0; i < this.appRoles.length; i++) {
        appRoleNames[i] = this.appRoles[i].name
      }
      // set an array only including app role names for filter
      let assignedAppRoleNames = [];
      for(let i = 0; i < this.$store.state.assignedMemberRoles.length; i++) {
        assignedAppRoleNames[i] = this.$store.state.assignedMemberRoles[i]
      }

      const availableAppRoles = appRoleNames.filter(function (obj) {
       return assignedAppRoleNames.indexOf(obj) === -1;
      })
      // set available app roles
      this.$store.commit('setAvailableMemberRoles', availableAppRoles)
      this.rolesLoading = false
    },
    updateUserRoles: async function (removeRoles, newRoles, userEmail, token) {
      this.rolesLoading = true
      if (newRoles.length > 0) {
        let data = {
          "email": userEmail,
          "roles": newRoles
        }
        axios.post('/adminportal/user/roles', data, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          this.$store.dispatch('alerts/openSuccessAlert', {
            content: `<div>Roles have been successfully assigned: </div><strong>${newRoles.join('<br/>')}</strong>`
          });
          
          // track gtm event
          window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'add user role', 'appName': this.$store.state.groupName });
          this.clearRoles()
          this.getUserRoles(userEmail)
        })
        .catch(error => {
          this.$store.dispatch('alerts/openErrorAlert', {
            content: `<div>Error when attempting to assign roles: </div><strong>${newRoles.join('<br/>')}</strong>
                      <br/><div>Please refresh and try again.</div>`
          });
          this.clearRoles()
          this.getUserRoles(userEmail)
          this.rolesLoading = false
        })
      }
      if (removeRoles.length > 0) {
        axios.delete("/adminportal/user/roles", {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          data: {
            'email': userEmail,
            'roles': removeRoles
          }
        })
        .then(response => {
          this.$store.dispatch('alerts/openSuccessAlert', {
            content: `<div>Roles have been successfully removed: </div><strong>${removeRoles.join('<br/>')}</strong>`
          });

          // track gtm event
          window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'remove user role', 'appName': this.$store.state.groupName });
          this.clearRoles()
          this.getUserRoles(userEmail)
          this.rolesLoading = false
        })
        .catch(error => {
          this.$store.dispatch('alerts/openErrorAlert', {
            content: `<div>Error when attempting to remove roles: </div><strong>${removeRoles.join('<br/>')}</strong>
                      <br/><div>Please refresh and try again.</div>`
          });
          this.clearRoles()
          this.getUserRoles(userEmail)
          this.rolesLoading = false
        })
      }
    },
    addRemoveRolesFromList: function (role) {
      if (this.$store.state.assignedMemberRoles.includes(role)) {
        // find where the element is in the array
        let roleIndex = this.$store.state.assignedMemberRoles.indexOf(role)
        // store the role
        const temp = role
        // splice from the current array
        this.$store.state.assignedMemberRoles.splice(roleIndex, 1)
        // add to new array
        this.$store.state.newAvailableMemberRoles.push(temp)
      }
      else if (this.$store.state.availableMemberRoles.includes(role)) {
        let roleIndex = this.$store.state.availableMemberRoles.indexOf(role)
        this.$store.state.availableMemberRoles.splice(roleIndex, 1)
        this.$store.state.newAssignedMemberRoles.push(role)
      }
      else if (this.$store.state.newAssignedMemberRoles.includes(role)) {
        let roleIndex = this.$store.state.newAssignedMemberRoles.indexOf(role)
        this.$store.state.newAssignedMemberRoles.splice(roleIndex, 1)
        this.$store.state.availableMemberRoles.push(role)
      }
      else if (this.$store.state.newAvailableMemberRoles.includes(role)) {
        let roleIndex = this.$store.state.newAvailableMemberRoles.indexOf(role)
        this.$store.state.newAvailableMemberRoles.splice(roleIndex, 1)
        this.$store.state.assignedMemberRoles.push(role)
      }
      if (this.$store.state.newAssignedMemberRoles.length < 1 && this.$store.state.newAvailableMemberRoles.length < 1) {
        this.$store.commit('setRolesUpdated', false)
      }
      else {
        this.$store.commit('setRolesUpdated', true)
      }
    },
    clearRoles() {
      this.$store.commit('setAssignedMemberRoles', [])
      this.$store.commit('setNewAssignedMemberRoles', [])
      this.$store.commit('setAvailableMemberRoles', [])
      this.$store.commit('setNewAvailableMemberRoles', [])
      this.$store.commit('setRolesUpdated', false)
    },
    getUserPermissions: async function (userEmail) {
      this.rolesLoading = true;
      axios.get('/adminportal/user/permissions', {
        headers: { Authorization: this.$store.state.token },
        params: {
          "email": userEmail
        }
      })
      .then(response => {
        let memberPermissions = [];
        for(const permission of response.data.result.permissions){
          for(const appPermission of this.appPermissions){
            if(permission.name === appPermission.name && permission.apiIdentifier === appPermission.apiIdentifier){
              permission.apiName = appPermission.api;
              memberPermissions.push(permission);
            }
          }
        }
        // Set assigned member roles array
        this.$store.commit('setAssignedMemberPermissions', memberPermissions)
        this.rolesLoading = false
        this.setAvailableAppPermissions(this.appPermissions);
      })
      .catch(error => {
        this.rolesLoading = false
      })
    },
    setAvailableAppPermissions:  function() {
      this.rolesLoading = true

      let availableAppPermissions = [];
        for(const appPermission of this.appPermissions){
          let isAssigned = false;
          for(const permission of this.$store.state.assignedMemberPermissions){
            if(appPermission.name === permission.name && appPermission.apiIdentifier === permission.apiIdentifier){
              isAssigned = true;
            }
          }
          if(!isAssigned) {
            appPermission.apiName = appPermission.api;
            availableAppPermissions.push(appPermission);
          }
        }

      this.$store.commit('setAvailableMemberPermissions', availableAppPermissions);
      this.rolesLoading = false;
    },
    addRemovePermissionsFromList: function (permission) {
      if (this.$store.state.assignedMemberPermissions.includes(permission)) {
        // find where the element is in the array
        let permissionIndex = this.$store.state.assignedMemberPermissions.indexOf(permission);
        // store the permission
        const temp = permission;
        // splice from the current array
        this.$store.state.assignedMemberPermissions.splice(permissionIndex, 1);
        // add to new array
        this.$store.state.newAvailableMemberPermissions.push(temp);
      }
      else if (this.$store.state.availableMemberPermissions.includes(permission)) {
        let permissionIndex = this.$store.state.availableMemberPermissions.indexOf(permission);
        this.$store.state.availableMemberPermissions.splice(permissionIndex, 1);
        this.$store.state.newAssignedMemberPermissions.push(permission);
      }
      else if (this.$store.state.newAssignedMemberPermissions.includes(permission)) {
        let permissionIndex = this.$store.state.newAssignedMemberPermissions.indexOf(permission);
        this.$store.state.newAssignedMemberPermissions.splice(permissionIndex, 1);
        this.$store.state.availableMemberPermissions.push(permission);
      }
      else if (this.$store.state.newAvailableMemberPermissions.includes(permission)) {
        let permissionIndex = this.$store.state.newAvailableMemberPermissions.indexOf(permission);
        this.$store.state.newAvailableMemberPermissions.splice(permissionIndex, 1);
        this.$store.state.assignedMemberPermissions.push(permission);
      }
      if (this.$store.state.newAssignedMemberPermissions.length < 1 && this.$store.state.newAvailableMemberPermissions.length < 1) {
        this.$store.commit('setPermissionsUpdated', false);
      }
      else {
        this.$store.commit('setPermissionsUpdated', true);
      }
    },
    updateUserPermissions: async function (removePermissions, newPermissions, userEmail, token) {
      this.rolesLoading = true;
      if (newPermissions.length > 0) {
        let permissions = [];
        for(const newPermission of newPermissions) {
            const permission = {"permission_name": newPermission.name, "resource_server_identifier" : newPermission.apiIdentifier};
            permissions.push(permission);
        }
        let data = {
          "email": userEmail,
          "permissions": permissions
        }
        await axios.post('/adminportal/user/permissions', data, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          this.$store.dispatch('alerts/openSuccessAlert', {
            content: `<div>Permissions have been successfully assigned: </div>
              ${newPermissions.map(perm => `<strong>${ perm.name }</strong>: ${perm.apiName} API`).join('<br/>')}
            `
          });

          // track gtm event
          window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'add user permission', 'appName': this.$store.state.groupName });
          this.clearPermissions();
          this.getUserPermissions(userEmail);
        })
        .catch(error => {
          this.$store.dispatch('alerts/openErrorAlert', {
            content: `<div>Error when attempting to assign permissions: </div>
              ${newPermissions.map(perm => `<strong>${ perm.name }</strong>: ${perm.apiName} API`).join('<br/>')}
              <br><div>Please refresh and try again.</div>`
          });
          this.clearPermissions();
          this.getUserPermissions(userEmail);
        });
      }
      if (removePermissions.length > 0) {
        let permissions = [];
        for(const removePermission of removePermissions) {
            const permission = {"permission_name": removePermission.name, "resource_server_identifier" : removePermission.apiIdentifier};
            permissions.push(permission);
        }
        await axios.delete("/adminportal/user/permissions", {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          data: {
            'email': userEmail,
            'permissions': permissions
          }
        })
        .then(response => {
          this.$store.dispatch('alerts/openSuccessAlert', {
            content: `<div>Permissions have been successfully removed: </div>
              ${removePermissions.map(perm => `<strong>${ perm.name }</strong>: ${perm.apiName} API`).join('<br/>')}
            `
          });

          // track gtm event
          window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'remove user permission', 'appName': this.$store.state.groupName });
          this.clearPermissions();
          this.getUserPermissions(userEmail);
        })
        .catch(error => {
          this.$store.dispatch('alerts/openErrorAlert', {
            content: `<div>Error when attempting to remove permissions: </div>
              ${removePermissions.map(perm => `<strong>${ perm.name }</strong>: ${perm.apiName} API`).join('<br/>')}
              <br><div>Please refresh and try again.</div>`
          });
          this.clearPermissions();
          this.getUserPermissions(userEmail);
        });
      }
      this.rolesLoading = false;
    },    
    clearPermissions() {
      this.$store.commit('setAssignedMemberPermissions', [])
      this.$store.commit('setNewAssignedMemberPermissions', [])
      this.$store.commit('setAvailableMemberPermissions', [])
      this.$store.commit('setNewAvailableMemberPermissions', [])
      this.$store.commit('setPermissionsUpdated', false)
    },
    selectRow: function(groupMember) {
      // close user menu
      this.closeUserMenu();

      // If group members is already selected, deselect it (splice from all selected arrays)
      if(this.selectedRowsIds.includes(groupMember.id)) {
        let i = this.selectedRowsIds.indexOf(groupMember.id);

        // Remove from selected arrays
        this.selectedRowsIds.splice(i, 1);
        this.selectedRowsEmails.splice(i, 1);
        this.selectedRowsStatus.splice(i, 1);

        // Add group member to selected arrays
      } else if (!this.selectedRowsIds.includes(groupMember.id)) {

        let i = this.selectedRowsIds.length;

        // populate selected row ids
        this.selectedRowsIds[i] = groupMember.id;

        // populate selected row emails
        this.selectedRowsEmails[i] = groupMember.email;

        // populate selected row statuses
        this.selectedRowsStatus[i] = groupMember.userStatus;
      }
      this.getGroupMembers();
    },
    selectAll: function (groupMembers) {
      // check if the user is currently using the search function and there is at least one result
      if (this.searchPattern != '') {
        this.selectAllSortedFilteredGroupMembers();
      } else {
        // Deselect all if length of group members  == length of selected group members
        if (this.selectedRowsIds.length == groupMembers.length) {
          this.selectedRowsIds = [];
          this.selectedRowsEmails = [];
          this.selectedRowsStatus = [];
          this.tooltipText = 'Select All';

          // Select all
        } else {
          for (let i = 0; i < groupMembers.length; i++) {
            if (!this.selectedRowsIds.includes(groupMembers[i].id)) {
              this.selectedRowsIds[i] = groupMembers[i].id;
              this.selectedRowsEmails[i] = groupMembers[i].email;
              this.selectedRowsStatus[i] = groupMembers[i].userStatus;
            } else if (this.selectedRowsIds.includes(groupMembers[i].id)) {
              continue;
            }
          }
          this.tooltipText = 'Deselect All';
        }

      }
      this.getGroupMembers();
    },
    selectAllSortedFilteredGroupMembers() {
       // Deselect All
      if (this.selectedRowsIds.length == this.sortedFilteredGroupMembers.length) {
        this.selectedRowsIds = [];
        this.selectedRowsEmails = [];
        this.selectedRowsStatus = [];
        this.tooltipText = 'Select All';
      } else {
        // Select All
        for (let i = 0; i < this.sortedFilteredGroupMembers.length; i++) {
          if (!this.selectedRowsIds.includes(this.sortedFilteredGroupMembers[i].id)) {
            this.selectedRowsIds[i] = this.sortedFilteredGroupMembers[i].id;
            this.selectedRowsEmails[i] = this.sortedFilteredGroupMembers[i].email;
            this.selectedRowsStatus[i] = this.sortedFilteredGroupMembers[i].userStatus;
          } else if (this.selectedRowsIds.includes(this.sortedFilteredGroupMembers[i].id)) {
            continue;
          }
        }
        this.tooltipText = 'Deselect All';
      }
    },
    changeMembersSorting: function(column) {
      if (column === this.membersSortingColumn){
        this.membersSortingOrder = this.membersSortingOrder === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.membersSortingColumn = this.membersSortingColumn === 'name' ? 'email' : 'name';
      }
    },
    closeGroup() {
      this.$store.commit("setGroupId", '');
      this.$store.commit("setGroupName", '');
      this.groupMembers = [];
      this.selectedRowsIds = [];
      this.selectedRowsEmails = [];
      this.selectedRowsStatus = [];

      this.resetPageSortingFilteringValues();

      this.invitedUsersSuccess = [];
      this.invitedUsersFail = [];
      this.addedUsersSuccess = [];
      this.addedUsersFail = [];
      this.invitedUserEmail = '';
      this.invitedUsers = [];
      this.invitedUsersLength = 0;

      this.userAddedSuccess = false;
      this.userAddedError = false;
      this.inviteSuccess = false;
      this.inviteError = false;

      this.clearRoles();
      
      this.closeUserMenu();

      this.$emit('close');
    },
    compareMembersByName(a,b) {
      if (!a.displayName) return -1;
      if (a.displayName > b.displayName) return 1;
      if (a.displayName < b.displayName) return -1;
      return 0;
    },
    compareMembersByEmail(a,b) {
      if (a.email > b.email) return 1;
      if (a.email < b.email) return -1;
      return 0;
    },
    getAppRoles: async function() {
      this.groupMembersLoading = true;
      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/roles', {
        headers: { Authorization: this.$store.state.token }
      })
      .then(response => {
        this.appRoles = response.data.result.roles;
        this.groupMembersLoading = false;
      })
      .catch(() => {
        this.groupMembersLoading = false;
      })
    },
    getAppPermissions: async function() {
      this.groupMembersLoading = true;

      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/apis', {
        headers: { Authorization: this.$store.state.token }
      })
      .then(response => {
        this.apis = response.data.result.apis;
        this.groupMembersLoading = false;
      })
      .catch(() => {
        this.groupMembersLoading = false;
      });

      let permissions = [];
      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/permissions', {
          headers: { Authorization: this.$store.state.token }
        })
        .then(response => {
          permissions = response.data.result.permissions;
          this.groupMembersLoading = false;
        })
        .catch(() => {
          this.groupMembersLoading = false;
        });      
      
        this.appPermissions = [];
        this.linkedApis = [];
        for (let permission of permissions) {
          for (let api of this.apis) {
            if(Object.keys(permission)[0] === api.id) {
              if(api.scopes) {
                for(let apiscope of api.scopes) {
                  this.appPermissions.push({"name": apiscope.value, "description": apiscope.description, "api": api.name, "apiIdentifier": api.identifier});
                }
              }
              this.linkedApis.push(api);
            }
          }
        }

    },
    addPermissionCompleted: function() {
      // track gtm event
      window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'add permission', 'appName': this.$store.state.groupName });

      this.getAppPermissions();
    }, 
    removePermissionCompleted: function() {
      // track gtm event
      window.dataLayer.push({ 'event': 'maintainApp', 'maintainType': 'remove permission', 'appName': this.$store.state.groupName });

      this.getAppPermissions();
    },
    downloadMembers() {
      this.closeUserMenu()
      try {
        const members = [...this.groupMembers];
        const columns = `id,displayName,email,userStatus\r\n`;

        const content = members.reduce((currentContent, currentItem) => {
          currentContent += `${currentItem['id']},${currentItem['displayName']},${currentItem['email']},${currentItem['userStatus']}\n`;
          return currentContent;
        }, columns);

        const element = document.createElement('a');
        element.href = `data:text/csv;charset=utf8,${encodeURI(content)}`;
        element.target = '_blank';
        element.download = `${this.$store.state.groupName}-members.csv`;
        element.click();

        this.$store.dispatch('alerts/openSuccessAlert', {
          content: `Your file will be ready soon. Please check your Downloads to view it.`
        });
      } catch (e) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Error occurred when downloading your file. Please try again.`
        });
      }
    },
    validateSelectedRowsStatus() {
      return this.selectedRowsStatus.length && this.selectedRowsStatus.every((val) => val == 'Pending')
    },
    toggleUploadDownloadMenu() {
      if(this.uploadDownloadMenuClicked == true) {
        this.uploadDownloadMenuClicked = false;
      } else if(this.uploadDownloadMenuClicked == false) {
        this.uploadDownloadMenuClicked = true;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .primaryButton, .secondaryButton {
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
    border: 2px #0D2F42 solid;
  }
  .primaryButton {
    background-color: #0D2F42;
    color: white !important;
  }
  .primaryButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .primaryButton:hover {
    opacity: 0.5;
  }
  .primaryButton:focus {
    outline: none;
  }

  .secondaryButton {
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
  }
  .secondaryButton {
    background-color: white;
    color: #0D2F42 !important;
  }
  .secondaryButton:hover {
    opacity: 0.5;
  }
  .secondaryButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .secondaryButton:focus {
    outline: none;
  }

  .resendDelete-btn {
    background-color: white;
    border-radius: 50px;
    transition: 0.25s;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
  }

  .uploadDownloadMenu-tr[disabled] {
    pointer-events: none;
    cursor: default;
    color: #575757;
  }

  .btn-icon-wrapper {
    float: left;
    white-space: normal;
  }

  .btn-text-wrapper {
    white-space: normal;
    text-align: left;
  }

  .resetSearchButton {
    background-color: #6c757d;
    color: white !important;
    font-size: 16px;
    transition: 0.25s;
    border: 2px #6c757d solid;
  }
  .resetSearchButton:hover {
    opacity: 0.5;
  }
  .resetSearchButton:focus {
    outline: none;
  }

  .B2Cbutton-add {
    color: white;
    font-size: 14px;
    font-weight: 600;
    background-color: #0D2F42;
    border: none;
    border-radius: 18px;
    padding: 4px 14px;

    transition: 0.25s;
  }

  .B2Cbutton-add:hover {
    background-color: #8cc460;
    cursor: pointer;
  }

  .B2Cbutton-cancel {
    color: #0D2F42;
    font-size: 14px;
    font-weight: 400;
    background-color: white;
    border: 2px #0D2F42 solid;
    border-radius: 18px;
    padding: 4px 14px;

    transition: 0.25s;
  }

  .B2Cbutton-cancel:hover {
    opacity: 0.5;
  }

  .openGroup-outer {
    width: 100%;
    height: 20px;
    margin-top: 10px;
  }

  .openGroup {
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    float: right;
    margin-right: 10px;
    transition: 0.15s;
  }

  .GroupsTile:hover .openGroup {
    margin-right: 0px;
  }

  @keyframes openGroup {
    from {
      top: -100%;
    }
    to {
      top: 0%;
    }
  }

  @keyframes openGroup-overlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .modalOverlay {
    background-color: rgb(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }

  .modalOverlay:hover {
    cursor: pointer;
  }

  .modal-background {
    position: absolute;
    top: 0;
    left: 50%;
    background-color: white;
    transform: translateX(-50%);
    overflow: hidden;

    animation-name: openGroup;
    animation-duration: 0.35s;
  }

  .closeBtn {
    position: absolute;
    color: #0D2F42;
    font-size: 1.2em;
    line-height: 0.75;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
  }

  .closeBtn:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .addNewMembersLoading {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .loadingMsg {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .load-wrapper {
    padding: 10px;
    display: flex;
  }

  .tableTitle-wrapper {
    margin-top: 16px;
  }

  .tableTitle {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #0D2F42;
    padding-left: 15px;
  }

  .assigned-app-roles-wrapper, .available-app-roles-wrapper {
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: #0D2F42 1px solid;
  }

  .roles-btns-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
    overflow-y: scroll;
    height: calc(100vh - 616px);
    padding: 10px;
  }

  .roles-tab-content-wrapper {
    background-color: rgba(13, 47, 66, 0.015);
    border-radius: 20px 20px 0px 0px;
    height: calc(100vh - 260px);
    margin-top: 30px;
    padding: 20px 40px;
  }
  .tab-content-h2-wrapper {
    text-align: center;
  }

  .app-header-title {
    display: block;
    font-size: 20px;
    color: #0D2F42;
    padding-left: 15px;
    margin-top: 20px;
  }

  .table-header-logo-wrapper {
    padding-right: 15px;
    border-right: #e2e2e2 1px solid;
    height: 70px;
  }

  .tab-content-h2 {
    font-size: 22px;
    font-weight: 400;
    margin: 0px;
  }
  .tab-content-h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
    margin-top: 10px;
  }
  .tab-content-separator {
    border-top: 1px #e2e2e2 solid;
  }
  .tab-content-header {
    font-size: 20px;
    color: #0D2F42;
    padding: 20px;
    margin: -20px -40px;
    border-radius: 20px 20px 0px 0px;
  }
  .tab-breadcrumb {
    float: left;
    font-size: 16px;
    transition: 0.2s;
  }
  .tab-breadcrumb:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  .tab-breadcrumb-text {
    padding-left: 5px;
  }
  .table-header-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    height: 70px;
    margin-left: 10px;
  }

  .tableRow-wrapper {
    display: table-row-group;
  }

  .tableRow:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.15)
  } 

  .tableRow:nth-child(even) {
    background-color: rgba(13, 47, 66, 0.02)
  }

  .tableRow {
    border-bottom: 1.5px solid #0D2F42;
    transition: 0.15s;
  }

  .tableRow:hover {
    background-color: rgba(13, 47, 66, 0.32);
    cursor:default;
  }

  .numUsers {
    float: right;
    padding: 5px 0px 5px 5px;
  }

  .tableHeader {
    background-color: #0D2F42;
    color: white;
    margin-bottom: 20px;
  }

  .tableHeader-title {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tableHeader-column1, .tableHeader-column2, .tableHeader-column3, .tableHeader-column4,  .tableHeader-column5,  .tableHeader-column6 {
    padding: 5px;
    border-right: 2px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }

  .tableHeader-column1 {
    border-radius: 50px 0 0 50px;
  }

  .tableHeader-column1, .tableHeader-column2 {
    cursor: pointer;
  }

  .tableHeader-column4, .tableHeader-column5 {
    border-right: 0;
  }

  .tableHeader-column6 {
    border-radius: 0 50px 50px 0;
    width: 50px;
  }

  .tableData {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 0px 15px 15px;
  }

  .tableDataActive {
    text-align: center;
    font-weight: 600;
    color: #1b8301;
    background-color: #c7ffb9;
    border-radius: 30px;
    margin-left: 15px;
  }

  .tableDataPending {
    text-align: center;
    font-weight: 600;
    color: #867200;
    background-color: #ffec82;
    border-radius: 30px;
    margin-left: 15px;
  }

  .selectedRowsIndicator {
    position: relative;
    top: -15px;
    left: 170px;
    height: 26px;
    min-width: 26px;
    padding: 0px 8px;
    font-size: 18px;
    text-align: center;
    color: white;
    background-color: #004EA8;
    border-radius: 15px;
  }

  .userMenu, .uploadDownloadMenu {
    position: absolute;
    max-width: 330px;
    background-color: white;
    color: #004EA8;
    padding: 5px;
    border-radius: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    z-index: 2;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
  }

  .userMenu {
    width: 330px;
    margin-left: -290px;
  }

  .uploadDownloadMenu {
    width: 100%;
    margin-top: 45px;
  }

  .uploadDownloadMenu .btn {
    text-align: left;
  }

  .userMenu tr, .uploadDownloadMenu tr {
    border-bottom: #d8d8d8 1px solid;
    max-width: 300px;

    transition: 0.2s;
  }
  .userMenu tr:last-child, .uploadDownloadMenu tr:last-child {
    border-bottom: none;
  }

  .userMenu tr:hover, .uploadDownloadMenu tr:hover {
    color: white;
    background-color: rgb(0, 78, 168);
    cursor: pointer;
  }

  .userMenu-title {
    color: #0D2F42;
    font-weight: 600;
    background-color: rgba(13, 47, 66, 0.03);
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .userMenu tr:first-child .userMenu-title {
    color: #0D2F42;
    cursor: default;
    background-color: rgba(13, 47, 66, 0.03);
  }
  .userMenu tr:first-child:hover {
    color: #0D2F42;
    cursor: default;
    background-color: rgba(13, 47, 66, 0.03);
    pointer-events: none;
  }

  .userMenu-data, .uploadDownloadMenu-data {
    padding: 10px;
  }

  .userMenu-data[disabled] {
    display: none;
  }

  .userMenu-icon, .uploadDownloadMenu-icon {
    margin-right: 5px;
  }

  .userMenu-icon .b-icon .bi .bi-three-dots:hover {
    cursor: pointer;
  }

  .userMenu-icon.trash {
    color: #ff5656;
  }

  .userMenu tr:hover .trash {
    color: white;
  }

  .userMenu-clickableBackground {
    position: absolute;
    display: block;
    height: 100%;
    width: 100vw;
    margin-top: -18.9%;
    z-index: 0;
  }

  .memberRoleTag {
    display: inline-block;
    min-width:fit-content;
    border: 2px solid;
    border-radius: 30px;
    padding: 2px 22px 4px 22px;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    transition: 0.2s;
    white-space: nowrap;
  }

  .memberRoleTag.assigned {
    background-color: #d8eaff;
    border-color: #d8eaff;
    color: #004EA8;
  }

  .memberRoleTag.assigned[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #F0F0F0;
    border-color: #999999;
    color: #4F4F4F;
    padding: 6px 22px 6px 22px;
  }

  .memberRoleTag.assigned[disabled] .b-icon {
    display: none;
  }

  .memberRoleTag.available {
    background-color: #0D2F42;
    border-color: #0D2F42;
    color: white;
  }

  .memberRoleTag.new {
    background-color: #e9ffdf;
    border-color: #215709;
    border-style: dotted;
    color: #215709;
  }

  .memberRoleTag.newAvailable {
    background-color: #eff2f5;
    border-color: #0D2F42;
    border-style: dotted;
    color: #0D2F42;
  }

  .memberRoleTag.new .b-icon {
    color: #215709;
  }

  .memberRoleTag.unassigned {
    border-color: #999999;
    color: #575757;
  }

  .memberRoleTag:hover {
    opacity: 0.65;
    cursor: pointer;
  }

  .togglePanel {
    background-color: white;
    color: #0D2F42;
    font-size: 14px !important;
    font-weight: 600;
    border: #0D2F42 2px solid;
    border-radius: 50px;
    transition: 0.25s;
  }

  .togglePanel:hover {
    opacity: 0.5;
  }

  .togglePanel:focus {
    outline: none;
  }

  .openInviteModal {
    background-color: #8cc460;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
    padding: 8px 22px;
    margin-left: 20px;
  }

  .openInviteModal:hover {
    opacity: 0.5;
    color: white;
  }

  .openInviteModal:focus {
    color: white;
  }

  .resend-invite-wrapper, 
  .delete-wrapper {
    display: grid;
    justify-content: center;
    text-align: center;
    transition: 0.2s;
    text-overflow: ellipsis;
    margin-top: 10px;
  }
  .resend-invite-wrapper:hover, 
  .delete-wrapper {
    cursor: pointer;
  }
  .resend-invite-wrapper:hover .action-icon {
    color: #0d2f425e;
  }

  .delete-wrapper:hover .action-icon {
    color: rgb(253, 88, 88);
  }

  .add-members-wrapper, 
  .upload-download-members-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
  }

  .actions-wrapper {
    margin-right: 15px;
    margin-left: 0px;
  }

  .additional-action-wrapper {
    display: flex;
    width: 100%;
    padding: 0px 20px 10px 10px;
    justify-content: end;
  }

  .resend-invite-btn, .delete-btn {
    height: 30px;
  }

  .resend-invite-btn:disabled, .delete-btn:disabled {
    margin-top: -10px;
  }

  .action-label {
    visibility: visible;
    color: #0D2F42 !important;
    font-size: 12px !important;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: -20px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-label-hidden {
    visibility: hidden;
    padding-bottom: 0px;
    margin-bottom: -10px;
    height: 0px;
  }

  .action-icon {
    color: #0D2F42;
  }

  .action-icon-disabled {
    color: #0d2f425e;
    margin-top: -20px;
  }

  .mainSelectionCircle {
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    line-height: 0.65;
    width: 30px;
    height: 30px;
    border: 2px white solid;
    border-radius: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    transition: 0.2s;
    float: right;
  }
  .mainSelectionCircle:hover {
    color: #0D2F42;
    background-color: white;
    cursor: pointer;
  }

  .mainSelectionCircle-selected {
    background-color: white;
  }

  :deep(.tooltip, .b-tooltip, .bs-tooltip-top) {
    font-size: 14px !important;;
  }

  :deep(.tooltip::after, .b-tooltip::after, .bs-tooltip-top::after) {
    font-size: 14px !important;;
  }

  .selectionCircle {
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    line-height: 0.65;
    width: 25px;
    height: 25px;
    border: 2px #0D2F42 solid;
    border-radius: 20px;
    margin-top: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    transition: 0.2s;
    float: right;
  }

  .selectionCircle:hover {
    background-color: #0D2F42;
  }

  .selectionCircle-selected {
    background-color: #0D2F42;
  }

  .check-icon {
    padding-bottom: 3;
  }

  .userRow-selected {
    background-color: #93c5ff !important;
  }

  .removeMember {
    background-color: rgb(253, 88, 88);
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    text-align: center;
    line-height: 0.65;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 6px;
  }

  .removeMember:hover {
    background-color: rgb(130, 20, 20);
  }

  .sidebar-wrapper {
    float: right;
    z-index: 100;
  }

  .sidebar {
    background-color: white;
  }

  .sidebar-inner {
    padding-left: 16px;
    padding-right: 16px;
  }

  :deep(.b-sidebar.b-sidebar-right) {
    background-color: #0D2F42;
    color: white;
    padding: 30px;
    height: 100%;
    width: 500px;
    bottom: 0;
    margin-top: auto;
    border-radius: 30px 0 0 0;
  }

  :deep(.b-sidebar.b-sidebar-right > .b-sidebar-header .close) {
    color: white;
    font-size: 2em;
  }

  :deep(.b-sidebar.b-sidebar-right ::-webkit-scrollbar) {
    height: auto;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    width: 15px;
  }

  :deep(.b-sidebar.b-sidebar-right ::-webkit-scrollbar-thumb) {
    height: auto;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
  }

  :deep(.b-sidebar.b-sidebar-right ::-webkit-scrollbar-corner) {
    background:#0D2F42;
  }

  .spacer {
    height: 20px;
    border-top: none;
  }

  .addInvitedUser {
    width: 100%;
    border: 1px solid #004EA8;
    float: left;
    text-transform: lowercase !important;
  }

  .addEmailFeild-wrapper {
    width: 100%;
  }

  .addInvitedUserBtn-wrapper {
    width: 40px;
    float: right;
    margin-top: 10px;
  }

  .addEmailBtn {
    height: 32px;
    width: 120px;
    border-radius: 5px;
    background-color: #6bbdff;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    float: right;
    padding-bottom: 5px;
  }

  .addEmailBtn:hover {
    opacity: 0.75;
  }

  .addEmailBtn:disabled {
    color: #D4D4D4;
    background-color: #4D556A;
  }
  .addEmailBtn:disabled:hover {
    color: #D4D4D4;
    background-color: #4D556A;
    opacity: 1;
    cursor: not-allowed;
  }

  .buttonsWrapper {
    margin-top: 20px;
  }

  .addMembers, .cancelBtn {
    font-weight: 600;
    border: none;
    border-radius: 40px;
    padding: 3px 10px;
  }

  .addMembers {
    color: white;
    background-color: rgb(115, 221, 119);
    align-self: end;
  }

  .edit-msg {
    margin-top: 15px;
  }

  .cancelBtn {
    background-color: rgb(255, 255, 255);
    color: #0D2F42;
  }

  .addMembers-wrapper {
    float: right;
  }

  .cancelBtn-wrapper {
    float: right;
    margin-right: 10px;
  }

  .nameIcon {
    width: 28px;
    height: 28px;
    border-radius: 20px;
    background-color: rgb(124, 207, 235);
    float: left;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }

  .newUserEmail {
    height: 35px;
    width: 100%;
    margin-top: 20px;
    /* text-align: end; */
    font-size: 1em;
    font-weight: 700;
    color: #0D2F42;
    background-color: white;
    padding: 5px 14px;
    border-radius: 20px;
  }

  .newUserEmail-inner {
    /* width: 225px;
    max-width: 225px; */
    overflow: hidden;
    text-overflow: ellipsis;
    /* float: left; */
    padding: 5px 10px;
    border-right: #0D2F42 solid 2px;
  }

  .removeUserEmailBtn {
    background-color: #ff5656;
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    border: none;
    height: 22px;
    width: 22px;
    margin-top: 2px;
    margin-right: 5px;
    border-radius: 30px;
    float: right;
  }

  .home-left {
    float: left;
    height: 100%;
  }

  .footer {
    position: fixed;
    bottom: 10px;
    width: calc(75% - 30px);
    background-color: #fff;
  }

  .hide-small {
    display: block;
  }

    .collapseBtn-wrapper {
      transition: 0.25s;
      padding-top: 20px;
      width: 48px;
      height: 68px;
      margin-left: 8px;
      /* background-color: tomato; */
    }

    .collapseBtn-collapsed-wrapper {
      transition: 0.25s;
      margin-left: 10px;
      padding-top: 20px;
      width: 48px;
      height: 68px;
    }

    .collapseBtn-wrapper:hover {
      cursor: pointer;
      transform: translateX(-3px) !important;
    }

    .collapseBtn-collapsed-wrapper:hover {
      cursor: pointer;
      transform: translateX(3px);
    }

    .collapseBtn {
      color: #0D2F42;
      font-size: 0.8em;
      line-height: 1.4em;
      width: 20px;
      height: 20px;
      background-color: rgba(255,255,255,0.75);
      border-radius: 30px;
      text-align: center;
      transition: 0.25s;
      margin: 10px;
    }

    .collapseBtn-collapsed {
      color: #0D2F42;
      font-size: 0.8em;
      line-height: 1.4em;
      width: 20px;
      height: 20px;
      background-color: rgba(255,255,255,0.75);
      border-radius: 30px;
      text-align: center;
      margin: 10px;
    }

    .collapseBtn::after {
      content: "🡠";
    }

    .collapseBtn-collapsed::after {
      content: "🡢";
    }

    .inviteModal {
      position: absolute;
      background-color: white;
      width: 85%;
      min-width: 300px;
      height: 90%;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .groups-header-wrapper {
      margin-bottom: 50px;
    }

    .groups-header {
      margin-left: 15px;
      padding-top: 25px;
    }

    .line-wrapper {
      position: absolute;
      /* width: calc(100% - 295.719px); */
      width: calc(100% - 30.719px);
      margin-left: 15px;
    }

    .line-connector-top {
      float: left;
      border-top: #0D2F42 solid 2px;
      width: 220px;
    }
    .line-connector-bottom {
      position: relative;
      border-bottom: #0D2F42 solid 2px;
      height: 50px;
      margin-left: 280px;
    }

    .curve-line {
      float: left;
      height: 50px;
    }

    .sort-order-transition {
      transition: transform 0.4s ease-in-out;
    }

    :deep(.members-status-dropdown) {
      border: solid 0px !important;
      background-color: transparent !important;
      color: inherit !important;
      font: inherit !important;
    }

    :deep(.members-status-dropdown:hover) {
      color: inherit !important;
      font: inherit !important;
    }

    :deep(.tableHeader .custom-control-label) {
      color: #000;
      font-size: 18px;
      margin-left: 12px;
      font-weight: normal;
    }

    .search-input {
      height: 36px;
    }

    .search-wrapper {
      padding: 20px 10px 0px 0px;
    }

    .search-btn {
      font-size: 14px;
      line-height: 16px;
      height: 36px;
    }

    .members-pagination-wrapper {
      position: absolute;
      width: 90%;
      margin: auto;
      bottom: 0px;
      height: 76px;
    }

    :deep(.page-link) {
      background-color: #ddd;
      border-radius: 50%;
      border: 0px;
      width: 38px;
      height: 38px;
      color: #000;
      font-weight: 550;
      margin: 0px 4px 0px 4px;
    }

    :deep(li.active > .page-link) {
      color: #fff;
      background-color: #0D2F42;
    }

    :deep(.prev-next-page-link) {
      background-color: unset;
      border: 0px;
      border-radius: 50% !important;
      width: 38px;
      height: 38px;
    }

    :deep(.prev-next-page-icon) {
      top: 2px;
      left: -1px;
      color: #0D2F42;
    }

    :deep(li.disabled .prev-next-page-icon) {
      color: #ddd;
    }

    .members-pagination {
      text-align: center;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .members-per-page {
      text-align: center;
      display: flex;
      justify-content: right;
      align-items: center;
      height: 76px;
      font-size: 16px;
    }

    .rows-per-page-label {
      padding-right: 10px;
    }

    .current-page-range {
      padding-left: 20px;
    }

    :deep( 
      .pagination > .active > a, 
      .pagination > .active > span, 
      .pagination > .active > a:hover,
      .pagination > .active > span:hover, 
      .pagination > .active > a:focus, 
      .pagination > .active > span:focus 
    ) {
      z-index: 3;
      color: #fff;
      cursor: default;
      background-color: #0D2F42 !important;
      border-color: #0D2F42 !important;
    }

  @media (max-width: 491px) {
    .uploadDownloadMenu {
      margin-top: 70px;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 767px) {

    .newUserEmail-inner {
      max-width: 150px;
    }

    .hide-small {
      display: none;
    }

    .label {
      color: white;
      font-size: 12px !important;
      font-weight: 600;
      text-transform: uppercase;
    }

    .label-blue {
      color: #0D2F42 !important;
      font-size: 12px !important;
      font-weight: 600;
      text-transform: uppercase;
    }

    .header-wrapper {
      width: 100%;
      height: 60px;
      margin: auto;
      margin-top: 0px;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 100%;
      height: 100%;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .modal-inner {
      padding: 20px;
    }

    .togglePanel {
      font-size: 12px !important;
      font-weight: 600;
      border-radius: 50px;
      transition: 0.25s;
      padding: 8px 10px;
    }

    .table-wrapper {
      position: absolute;
      float: left;
      width: calc(100% - 10px);
      overflow: scroll;
      height: calc(100% - 510px);
    }

    .tableHeader-title {
      font-size: 12px;
    }

    .tableData {
      font-size: 12px;
    }

    .tableDataActive {
      width: 60px;
      font-size: 10px;
      text-align: center;
      font-weight: 600;
      border-radius: 30px;
      padding: 4px 0px;
    }

    .tableDataPending {
      width: 90px;
      font-size: 10px;
      text-align: center;
      font-weight: 600;
      border-radius: 30px;
      padding: 4px 0px;
    }

    .groups-header-wrapper {
      margin-top: 120px;
    }

    .footer {
      width: 100%;
      margin: auto;
      margin-left: -15px;
      bottom: 25px;
    }

    .groupMembersLoading {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      bottom: 0px;
      text-align: center;
    }

    .add-members-wrapper, 
    .upload-download-members-wrapper {
      padding: 30px 10px 0px 0px;
      justify-content: center;
    }
    
    .search-input {
      height: 50px;
      font-size: 20px;
    }

    .search-wrapper{
      padding: 20px 0px 0px 0px;
    }

    .search-btn {
      height: 50px;
    }

    .actions-wrapper {
      padding: 0px 20px;
      margin-bottom: 20px;
    }

    .members-pagination-wrapper {
      height: 100px;
    }

    .members-pagination {
      padding: 0px;
      justify-content: center;
    }

    .members-per-page {
      justify-content: center;
      height: 38px;
      padding: 0px;
    }

    :deep(.page-link) {
      width: 30px;
      height: 30px;
      font-size: 14px;
      margin: 0px 2px 0px 2px;
    }

    :deep(ul.pagination) {
      height: 30px;
      margin: 10px 0px;
    }

    :deep(.prev-next-page-link) {
      width: 30px;
      height: 30px;
    }

    :deep(.prev-next-page-icon) {
      top: -1px;
      left: -5px;
    }

    .app-header-title {
      max-width: 134px;
      float: right;
      margin-top: 8px;
    }

    .tab-content-h2 {
      display: none;
      /* font-size: 16px;
      margin-top: 40px;
      padding-top: 10px;
      border-top: #0D2F42 1px solid; */
    }

    .roles-tab-content-wrapper {
      height: calc(100vh - 210px);
    }

    .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
      overflow-y: scroll;
      height: calc(100vh - 720px);
    }

    .assigned-app-roles-wrapper {
      border-top: #0D2F42 1px solid;
    }

    .assigned-app-roles-wrapper, .available-app-roles-wrapper {
      margin-top: 20px;
    }

    .available-app-roles-wrapper {
      margin-top: 50px;
    }

    .memberRoleTag {
      font-size: 22px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .tab-content-h3 {
      font-size: 16px;
    }

    .userMenu tr:hover, .uploadDownloadMenu tr:hover {
      color: rgb(0, 78, 168);
      background-color: white;
      cursor: default;
    }

    .userMenu-data, .uploadDownloadMenu-data {
      font-size: 16px;
    }

    .primaryButton:hover, .secondaryButton:hover {
      opacity: 1;
      cursor: default;
    }

    .upload-download-members-btn-text {
      padding-left: 6px;
    }

    .resend-invite-wrapper, 
    .delete-wrapper {
      padding-top: 20px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .newUserEmail-inner {
      max-width: 250px;
    }


    .hide-small {
      display: block;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 90%;
      height: 95%;
      border-radius: 0 0 50px 50px;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .modal-inner {
      padding: 40px;
    }

    .togglePanel {
      font-size: 16px;
      font-weight: 600;
      border-radius: 50px;
      transition: 0.25s;
      padding: 8px 12px;
    }

    .label {
      color: white;
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
    }

    .table-wrapper {
      position: absolute;
      float: left;
      width: calc(100% - 80px);
      overflow: scroll;
      height: calc(100% - 400px);
      padding: 0px 10px;
      margin-top: 10px;
    }

    .tableHeader-title {
      font-size: 18px;
    }

    .tableData {
      font-size: 18px;
    }

    .tableDataActive {
      width: 85px;
      font-size: 16px;
      text-align: center;
      margin: 0px 0px 15px 15px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: 600;
      border-radius: 30px;
    }

    .tableDataPending {
      width: 120px;
      font-size: 16px;
      text-align: center;
      margin: 0px 0px 15px 15px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-weight: 600;
      border-radius: 30px;
    }

    .groupMembersLoading {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      bottom: 0px;
      padding-bottom: 0px;
      text-align: center;
    }

    .footer {
      bottom: 10px;
    }

    .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
      overflow-y: scroll;
      height: calc(100vh - 820px);
      padding: 10px;
    }

    .uploadDownloadMenu {
      margin-top: 45px;
    }

    .userMenu-data, .uploadDownloadMenu-data {
      font-size: 16px;
    }

    .userMenu tr:hover, .uploadDownloadMenu tr:hover {
      color: white;
      background-color: rgb(0, 78, 168);
      cursor: pointer;
    }

    .primaryButton:hover, .secondaryButton:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    .upload-download-members-btn-text {
      padding-left: 0px;
    }

    .search-wrapper{
      padding: 20px 0px;
    }
  }

  @media only screen and (min-width: 820px) {
    .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
      overflow-y: scroll;
      height: calc(100vh - 900px);
      padding: 10px;
    }

    .tab-content-h3 {
      font-size: 16px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .hide-small {
      display: block;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 90%;
      height: 95%;
      border-radius: 0 0 50px 50px;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .table-wrapper {
      height: calc(100% - 410px);
    }

    .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
      overflow-y: scroll;
      height: calc(100vh - 460px);
      padding: 10px;
    }
  }

  @media only screen and (min-width: 1180px) {
    .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
      overflow-y: scroll;
      height: calc(100vh - 540px);
      padding: 10px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .hide-small {
      display: block;
    }

    .modal-background {
      position: absolute;
      top: 0;
      left: 50%;
      background-color: white;
      width: 90%;
      height: 95%;
      border-radius: 0 0 50px 50px;
      transform: translateX(-50%);
      overflow: hidden;

      animation-name: openGroup;
      animation-duration: 0.35s;
    }

    .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
      overflow-y: scroll;
      height: calc(100vh - 570px);
      padding: 10px;
    }

    .table-wrapper {
      height: calc(100% - 340px);
    }
  }

  @media only screen and (min-width: 1500px) and (max-width: 1919px) {
    .assigned-app-roles-wrapper-inner, .available-app-roles-wrapper-inner {
      overflow-y: scroll;
      height: calc(100vh - 512px);
      padding: 10px;
    }
  }
  
</style>